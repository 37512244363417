import * as React from "react"

import { FaGlobe } from "react-icons/fa"

import styles from "./language-select.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

// NOTE: not using built-in `Link` component because it doesn't work
export const LanguageSelect = ({ langs }) => (
  <div className={cx("language-select")}>
    <FaGlobe />
    {langs.map(({ langKey, name, isSelected, to }) => (
      <a
        key={langKey}
        href={to}
        className={cx("lang", { "lang--selected": isSelected })}
      >
        {name}
      </a>
    ))}
  </div>
)
