import React, { useRef, useState } from "react"
import { OffscreenMenu } from "../offscreen-menu"
import { StartMenuPanel } from "./start-menu-panel"

import MenuIcon from "./menu.svg"

import styles from "./start-menu.module.scss"
import classnames from "classnames/bind"
import { MenuContext } from "./context"
const cx = classnames.bind(styles)

const useDropdownState = (dropdownRef, menuRef = null) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = () => {
    setIsOpen(true)
    window.setTimeout(() => {
      menuRef?.current?.focus() //eslint-disable-line
    }, 0)
  }
  const onClose = () => {
    setIsOpen(false)
    dropdownRef.current?.focus() //eslint-disable-line
  }

  return [isOpen, { onOpen, onClose }]
}

export const StartMenu = ({ header, menuRef, children }) => {
  const dropdownRef = useRef()
  const [isOpen, { onOpen, onClose }] = useDropdownState(dropdownRef, menuRef)

  const label = (
    <div className={cx("start-menu_button")}>
      <img src={MenuIcon} alt="menu" />
    </div>
  )

  return (
    <div className={cx("start-menu")}>
      <OffscreenMenu
        ref={dropdownRef}
        label={label}
        alignment="top"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <MenuContext.Provider value={{ onOpen, onClose }}>
          <StartMenuPanel header={header}>{children}</StartMenuPanel>
        </MenuContext.Provider>
      </OffscreenMenu>
    </div>
  )
}
