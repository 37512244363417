import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/characters/tourmaline/",
  "title": "Terranova - Tourmaline",
  "langKey": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/40095b87192498b96b2c6f84231fe978/e17e5/tourmaline-profile2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAADXUAAA11AFeZeUIAAADJElEQVQozwEZA+b8AOLde8G+dZCOboJ5fJ+WjLKtmJiim52zqX2ds5q4wXlxd5uTkZGip2lVXIqIhNvuzu/93Pr13Pz25Pf95gD+/ov//4vn5pOkrJ6tuK/J1r3B49nA4eCt1tvt9+OTal9xMy5cLCt9RT2fW06+e17wxZPM1LbS6NPY9+AA4NyQ4+KTytGcscGfm7anrs28w7yguqqbr7y6sK6jp2hVekI6YTMvik9CiVRGk1hMw3JN4KdtysWtlZqoAP79o///t+zzt83brL/VvrrIs8OukcPPtohuYIxRP4xTRpxiUrqHd6p1Zo1TRIhVR5FXRqNuUHdXXFJGcADR3IzP2Zzk77Xt8cvx89nS49XK4Nqrg22FSTmCT0OOX1Pcspr0yq66kIF9TkKBU0eFUkKFUDpoRlg/MGUAzt6I6u2w3uW56OvE7/XRv+bVv+/Wknpvd0tEnnJjv5B9oXp1rYJ51qaQjmNacFNRelROg1hJbEhUTjZdALDXdJXSi8vcusLOuL7PvLXYxp/rz4HIw3Z1fbGGedWmi8OKbphmVt2vlKB8eVJOYGtcZX5dVlgzUUghTgCTzWWUyYC44MLK38e9y7XNyayEyMZm3dp40tmtkoitjYH/zKb/2rLtvJywgGxTOkBfYHRuXGFiUWVSOFkARqdnYcCFgtS/u8Gp88CaysCdebGoV8HJUbjLlaqssYt548az5qOJ7q2S8bmTyH5mfGltXVdsaG2Cbn+WABuRZCaiZnS9rKjKrLHAnNHFoZu+pki6wkq/ylOtv4uVls6ok+ipi9aiiY+GlGt5iXeyr1FqiWd2i63dugAunVVgjmp2vsS1zLLPy6jgxKpyxMCpyrHAw6hhvsJJrsNjlq1nhp5zZW17i5pousdLn7ppboGFe3p9wb0AN51iTHVxPHWLUGqLW3ejRHiZO3eapKSi/9m3wNHMgb6+a7O+nry64MW1/8yjzcGqdYuTj2BWr4l4s5OBACmxdkWEbDFidytNextDixA7gic3bWqMs////9jy6cLq2uz59ubd0ffMsqajqmuHpV90jbKPfIiPh6l7bpcy1PZR32RlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "tourmaline profile2",
            "title": "tourmaline profile2",
            "src": "/static/40095b87192498b96b2c6f84231fe978/e17e5/tourmaline-profile2.png",
            "srcSet": ["/static/40095b87192498b96b2c6f84231fe978/222b7/tourmaline-profile2.png 163w", "/static/40095b87192498b96b2c6f84231fe978/ff46a/tourmaline-profile2.png 325w", "/static/40095b87192498b96b2c6f84231fe978/e17e5/tourmaline-profile2.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6bbf4503966a759dd7f5328920c506b0/e17e5/eytan-profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAADXUAAA11AFeZeUIAAACr0lEQVQoz2OQLDyEhqSKDsmVHTEoWOUUlGlo6qShY69tGq6dv0698bRy5RGZksNwlQxoOmWKDylVHdNpOhEfk+VjaWVh7Kiv7aStYK9lnmjYcUKv9aR63TH58iNYNEsXH1atPqrZcsajbWdFXGqEf46bc56LeZytQbShbqhDxy7LSRcN209pNpxQAOtH0axYeVSv6bhR/8Wo1nVFIZFpgZmBzjkRDuFFAaWJrklJ/Zvt51yzmXDGqPO0Rv1x2dIjCM2ypYc1G46bdJ2ynXbJt3FdZGxBVUxsf2rh8qrCaVnVES7ZodP2+C297jrzvNWEs/ptJ1WqjyE0KwGtbT0JlABK+6+8HtKw8sDEht3dlXOKimekNoZ7lYWuuhi+4Y7XgsuO0y+Y9pzWaoBpli46pFZ7zKjzlP2U817zL0VuuBU+/UBlXsOB+Z2LmjtqE4vjQ1PTa5rTFh+NXHjBZcZp695TGq1nGWCBfBjoDdPu047Tz/stuhS1/kbS7gcZU9bkJMUWFycUVSbqaDvaGVjHByZ1NaxsbN9ZV9YUVL0AprkE5GHTnjPOM877LbsRs/pi/uSFGWlJ+irqrp5O7b152trGGqo2Tlah+YXrktJXhXmEFYaGoths1n3KfvqFgNnHinNy0l0tXUyMDHUNJcQVk9NjHJxdpMTVbe0zfBI3OwR2O7u4+rh7MsBTlXrNUe3uC7Ht68oSMqOc7JwsLfW19RUVNYRFFIJCg/2Dg4QFFQ1sy6zD1ps6FZiamtraOcE0Fx5UqDxu0bgnJz7P18zNztRWS1VPWVHTwEBfz0AvLiUkITVEW9fQyL3HLHC5umGIspqFlrYzVLN4/iGVqqPFObmZ/oHGOg7aquZqqtox8V6e7jaGhgZxSaGllenGJlYWgQtNfGZrGbhr6jpbWHoCABArQGLUIDCJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "eytan profile",
            "title": "eytan profile",
            "src": "/static/6bbf4503966a759dd7f5328920c506b0/e17e5/eytan-profile.png",
            "srcSet": ["/static/6bbf4503966a759dd7f5328920c506b0/222b7/eytan-profile.png 163w", "/static/6bbf4503966a759dd7f5328920c506b0/ff46a/eytan-profile.png 325w", "/static/6bbf4503966a759dd7f5328920c506b0/e17e5/eytan-profile.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* TODO: This is meant to be a partial, not a page. Don't include frontmatter, and figure out how to exclude it from page generation */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      