import * as React from "react"
import { Link } from "gatsby"
import { FaGlobe } from "react-icons/fa"
import { WMAnimationContainer, WMWindow } from "@team-chatt/window-manager"
import { Body } from "./body"
import { Center } from "./center"
import { StartMenu, StartMenuItem } from "./start-menu"
import { NavBar, NavBarItem, NavBarSpacer } from "./nav-bar"
import { LanguageSelect } from "./language-select"

import "@team-chatt/window-manager/dist/main.css"

import styles from "./layout.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

const noop = () => {}

const startMenuHeader = <>Site links</>

const Footer = ({ langs }) => (
  <footer className={cx("footer")}>
    <div>
      <h3>Site Map</h3>
      <ul>
        <li>
          <Link to={`/`}>Home</Link>
        </li>
        <li>
          <Link to={`/characters/`}>Characters</Link>
        </li>
        <li>
          <Link to={`/press/`}>Press</Link>
        </li>
      </ul>
    </div>

    <div>
      <h3>Languages</h3>
      <ul>
        {langs.map(({ langKey, name, to }) => (
          <li key={langKey}>
            <a href={to}>{name}</a>
          </li>
        ))}
      </ul>
    </div>

    <div className={cx("footer_copyright")}>
      <Center>
        <small>&copy; 2020-2023, Studio Terranova</small>
      </Center>
    </div>
  </footer>
)

export const Layout = ({ children, langs }) => (
  <div className={cx("desktop")}>
    <div className={cx("container")}>
      <WMAnimationContainer>
        <WMWindow
          title="Terranova"
          position={{ top: 0, left: 0 }}
          dimensions={{ width: "100%", height: "100%" }}
          isFocused={true}
          onMinimize={noop}
          onClose={noop}
          onFocus={noop}
          onBlur={noop}
          onMove={noop}
        >
          <main className={cx("main")}>
            <div className={cx("menu", "menu--desktop")}>
              <NavBar>
                <NavBarItem to={`/`}>Home</NavBarItem>
                <NavBarItem to={`/characters/`}>Characters</NavBarItem>
                <NavBarItem to={`/press/`}>Press</NavBarItem>
                <NavBarItem
                  href={`https://illuminesce.notion.site/Artists-of-Terranova-82f5443cfc7f49c6a1a0291cc859d983`}
                >
                  Artist showcase
                </NavBarItem>
                <NavBarSpacer></NavBarSpacer>
                <NavBarItem href={`https://discord.gg/NUg8ny8Qv2`}>
                  ✨ Join our Discord ✨
                </NavBarItem>
                <LanguageSelect langs={langs}></LanguageSelect>
              </NavBar>
            </div>
            <div className={cx("menu", "menu--mobile")}>
              <StartMenu header={startMenuHeader}>
                <StartMenuItem to={`/`}>Home</StartMenuItem>
                <StartMenuItem to={`/characters/`}>Characters</StartMenuItem>
                <StartMenuItem to={`/press/`}>Press</StartMenuItem>
                <StartMenuItem
                  href={`https://illuminesce.notion.site/Artists-of-Terranova-82f5443cfc7f49c6a1a0291cc859d983`}
                >
                  Artist showcase
                </StartMenuItem>
                <StartMenuItem href={`https://discord.gg/NUg8ny8Qv2`}>
                  Join our Discord
                </StartMenuItem>
                <hr className={cx("menu_divider")} />
                <div className={cx("menu_section")}>
                  <div className={cx("menu_heading")}>
                    <FaGlobe /> Languages
                  </div>
                  {langs.map(({ langKey, name, isSelected, to }) => (
                    <StartMenuItem
                      key={langKey}
                      href={to}
                      isSelected={isSelected}
                    >
                      {name}
                    </StartMenuItem>
                  ))}
                </div>
              </StartMenu>
            </div>

            <Body>
              {children}

              <Footer langs={langs}></Footer>
            </Body>
          </main>
        </WMWindow>
      </WMAnimationContainer>
    </div>
  </div>
)
