import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/press/images/",
  "title": "Terranova - Press Kit Images",
  "langKey": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/15fc9e8b303f951c3f0253ffbed70a4d/772e8/terranova-square-logo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terranova square logo",
            "title": "terranova square logo",
            "src": "/static/15fc9e8b303f951c3f0253ffbed70a4d/772e8/terranova-square-logo.png",
            "srcSet": ["/static/15fc9e8b303f951c3f0253ffbed70a4d/222b7/terranova-square-logo.png 163w", "/static/15fc9e8b303f951c3f0253ffbed70a4d/772e8/terranova-square-logo.png 200w"],
            "sizes": "(max-width: 200px) 100vw, 200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c8609a4618a676a1c3659e60074dc217/0a47e/terranova-logo-outline-600px.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20.245398773006134%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terranova logo outline 600px",
            "title": "terranova logo outline 600px",
            "src": "/static/c8609a4618a676a1c3659e60074dc217/0a47e/terranova-logo-outline-600px.png",
            "srcSet": ["/static/c8609a4618a676a1c3659e60074dc217/222b7/terranova-logo-outline-600px.png 163w", "/static/c8609a4618a676a1c3659e60074dc217/ff46a/terranova-logo-outline-600px.png 325w", "/static/c8609a4618a676a1c3659e60074dc217/0a47e/terranova-logo-outline-600px.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/df2e7243a3233b4980b9180e25d34a2e/0a47e/terranova-article-dkblue-logo-x1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.668711656441715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWklEQVQoz3WS3U+SYRjGrwfmNJduHlgdqBmg4geKGKbgUFJKcZSLQSEN8OVDXz5UkFcaGiCEpKjQB6alzrnZLEVXbdlWW/9Ff0aHHfe+Llwnbs/p777v5/pdgGIWt4KQByCbhnQSrV40u9E4gQYn6hwQUhCM4cYFD71h9DyBchZdhRFtPkg8aKLR4OJ40cU8NDHcjhD1HL8vBGWIdDNEHiCySV6bjyfx8BonSL2DX2cnojEIbDwBxRNSREAV4OEk0cYJO0KZhGoOXQzkM2ifRouHO77eBZEDNVZcp1BlR5WFe9XWfzDRL2M4Xa5f6F0brDDGDZEPGv9Wv++tml7vd71ufZCs10bU5sy1QaryoU6qjdy8F6vtCaLawu3HoyyMa+7TjqM/qKQDpsRnezofzH2N7/xIbH+nYvuh7PGn01/KEH11qjuwuhPPfDF6c6g08YV2wLQ5tN8b+w3PT0GJeXFw/mA0dTS2fOxcyntXTjxLeX/6MJL5pmL8xRb5iDczkzigmC1289mf7e9qgiFJhCqzJKHPFhvSV8zZ8pFUmTZx+U60YuDpJQVT2hkoktF8mbWoYby02VUidrGxncHuXVB7eLxLLG8w+gKGFehSGEpgIMpZZFvA5sf6l3ghpjlzAhtqbYW0p/aJb5fn3iLODVjXyehLYlgl95eI9hnRRElfmChCrDxIfWhhzY8TVtu5djAf4X8P3x4mduDYhCXHRahPQ7eIuwtQz3MV6gyifYqTJx6HyP5fScInYA4L/DaoDZhfsflj5Dm0Z8erwuhm0OHnmttEs505h/8CfrPEYpaZAKkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terranova article dkblue logo x1",
            "title": "terranova article dkblue logo x1",
            "src": "/static/df2e7243a3233b4980b9180e25d34a2e/0a47e/terranova-article-dkblue-logo-x1.png",
            "srcSet": ["/static/df2e7243a3233b4980b9180e25d34a2e/222b7/terranova-article-dkblue-logo-x1.png 163w", "/static/df2e7243a3233b4980b9180e25d34a2e/ff46a/terranova-article-dkblue-logo-x1.png 325w", "/static/df2e7243a3233b4980b9180e25d34a2e/0a47e/terranova-article-dkblue-logo-x1.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b07be91186df5a20a55e0b39d3fdb53/0a47e/terranova-article-dkblue-tagline-x1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.668711656441715%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaklEQVQoz3WS609ScRzGn59ia6vw0rrNNU3QQJOcluUFENRwECpJ3oUDnADPDuNgDJFAkxQRDM1bMqK0TdtqypavXS97V/1DbZ1jF+tF2/ft5/s8e54HaA7gjh+3J1DPoc4DBYsbDORuXHdC6oDUjgobrv3noA6hNYiWAJr8aJwgDRyp8xAFS6rHicxFKmkitROJnRy/IPz9/QudT6GdJtoQUU4JLn5aUHhQy0I+DpkLlTQEmEI5D1DkH2XDPDHMQvssvzMs0oTFuplz7eEzqinRLd8p3kUNI+hX0XlSh0hmEUnovArbCU/6FqFbLh0LRj6XXXYyI7MfXUv7puAOm9j3JvYNzKaGSgUWcnddjy8yrQZ3fNSb6RhJ4MpIvsQB9K+K3U+4b6W57yjlrMbgAbd++ChxENs+iu8c+Z/nZl8e7uW+utMLBZTCFl1Zz37yx96hnDq2PZhRr/WwX4rvv1HDNC93p/WR3WbvK40v2+7N6H2ZNueqgUnXDE8WmFXV+oBqMN45ljwtc6KMAuhMnmXjrGWRmFeJOSU2JYqNMXFXtFA7XdIWLmmeLGr0F9Z7S+q4Czd9lxRsER/BVetvZWYbriwcGUKtk+EXLbbNAXpLObDcMbRisq6pe+LdAynTg6SyK3rPONfbGx96uHS+luFlhdjA7YLdIeNZ0GlYN8ngCulLonsBXVFoImidEibU4BOWw9cmcfBp/5IVYP97cHvwvIX7tcBbNgTenCTGGNFFiTZClEGeJ/VeUssSuZvwszvpOZRD4AN8e2B5Pgv7FkbX0J9C7yL0c+iYgSqEpgAaOGG5/Oyq6D/wD2dCy2DRPKhWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terranova article dkblue tagline x1",
            "title": "terranova article dkblue tagline x1",
            "src": "/static/6b07be91186df5a20a55e0b39d3fdb53/0a47e/terranova-article-dkblue-tagline-x1.png",
            "srcSet": ["/static/6b07be91186df5a20a55e0b39d3fdb53/222b7/terranova-article-dkblue-tagline-x1.png 163w", "/static/6b07be91186df5a20a55e0b39d3fdb53/ff46a/terranova-article-dkblue-tagline-x1.png 325w", "/static/6b07be91186df5a20a55e0b39d3fdb53/0a47e/terranova-article-dkblue-tagline-x1.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/537ed6f3b3217f78419c3c4f36f3c28f/47f6c/terranova-logo-screenshot-article.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.963190184049076%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terranova logo screenshot article",
            "title": "terranova logo screenshot article",
            "src": "/static/537ed6f3b3217f78419c3c4f36f3c28f/a6d36/terranova-logo-screenshot-article.png",
            "srcSet": ["/static/537ed6f3b3217f78419c3c4f36f3c28f/222b7/terranova-logo-screenshot-article.png 163w", "/static/537ed6f3b3217f78419c3c4f36f3c28f/ff46a/terranova-logo-screenshot-article.png 325w", "/static/537ed6f3b3217f78419c3c4f36f3c28f/a6d36/terranova-logo-screenshot-article.png 650w", "/static/537ed6f3b3217f78419c3c4f36f3c28f/e548f/terranova-logo-screenshot-article.png 975w", "/static/537ed6f3b3217f78419c3c4f36f3c28f/3c492/terranova-logo-screenshot-article.png 1300w", "/static/537ed6f3b3217f78419c3c4f36f3c28f/47f6c/terranova-logo-screenshot-article.png 2206w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5dba120f29f300d5a5448cadce87cbab/47f6c/terranova-logotagline-screenshot-article.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.963190184049076%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terranova logotagline screenshot article",
            "title": "terranova logotagline screenshot article",
            "src": "/static/5dba120f29f300d5a5448cadce87cbab/a6d36/terranova-logotagline-screenshot-article.png",
            "srcSet": ["/static/5dba120f29f300d5a5448cadce87cbab/222b7/terranova-logotagline-screenshot-article.png 163w", "/static/5dba120f29f300d5a5448cadce87cbab/ff46a/terranova-logotagline-screenshot-article.png 325w", "/static/5dba120f29f300d5a5448cadce87cbab/a6d36/terranova-logotagline-screenshot-article.png 650w", "/static/5dba120f29f300d5a5448cadce87cbab/e548f/terranova-logotagline-screenshot-article.png 975w", "/static/5dba120f29f300d5a5448cadce87cbab/3c492/terranova-logotagline-screenshot-article.png 1300w", "/static/5dba120f29f300d5a5448cadce87cbab/47f6c/terranova-logotagline-screenshot-article.png 2206w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* TODO: This is meant to be a partial, not a page. Don't include frontmatter, and figure out how to exclude it from page generation */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      