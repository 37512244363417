import * as React from "react"
import { Layout as LayoutEN } from "./layout.en"
import { Layout as LayoutJP } from "./layout.jp"
import { withPrefix } from "gatsby-link"

const getLangFromPath = (path) =>
  ["en", "jp"].find((lang) => path.startsWith(`/${lang}/`))

const getSlugFromPath = (path) => {
  const lang = getLangFromPath(path)
  if (lang) {
    return path.slice(`/${lang}`.length)
  }
  return path
}

const getPath = (langKey, slug) =>
  langKey === "en" ? slug : `/${langKey}${slug}`

export const Layout = ({ children, location }) => {
  const currentLang = getLangFromPath(location.pathname) ?? "en"
  const slug = getSlugFromPath(location.pathname)

  const langsMenu = ["en", "jp"].map((lang) => ({
    langKey: lang,
    isSelected: lang === currentLang,
    name: lang.toUpperCase(),
    to: withPrefix(getPath(lang, slug)),
  }))

  switch (currentLang) {
    case "en":
      return <LayoutEN langs={langsMenu}>{children}</LayoutEN>
    case "jp":
      return <LayoutJP langs={langsMenu}>{children}</LayoutJP>
    default:
      return <LayoutEN langs={langsMenu}>{children}</LayoutEN>
  }
}
