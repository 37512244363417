import React from "react"
import { Link } from "gatsby"

import styles from "./start-menu-item.module.scss"
import classnames from "classnames/bind"
import { useMenuContext } from "./context"
const cx = classnames.bind(styles)

export const StartMenuItem = ({ to, href, isSelected, children }) => {
  const { onClose } = useMenuContext()
  return href ? (
    <a
      href={href}
      className={cx("start-menu-item", {
        "start-menu-item--selected": isSelected,
      })}
    >
      <div className={cx("start-menu-item_layout")}>{children}</div>
    </a>
  ) : (
    <Link to={to} className={cx("start-menu-item")} onClick={onClose}>
      <div className={cx("start-menu-item_layout")}>{children}</div>
    </Link>
  )
}
