import React from "react"

import styles from "./nav-bar.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const NavBar = ({ children }) => (
  <header className={cx("nav-bar")}>
    <nav className={cx("nav-bar_list")}>{children}</nav>
  </header>
)
