import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/characters/ephemeralsmile/",
  "title": "Terranova - Ephemeralsmile",
  "langKey": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cbb8d5ef4c489fc1691dcb3b19ee8b74/e17e5/effie-profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAADXUAAA11AFeZeUIAAADJElEQVQozwEZA+b8AH9/iNLJqt3VtoFScUwgX00lZVQoaDwfUi4dK3xzZGl1bxADHImTmbS3kWlZR7S4r7K8uNXl1djFm+yjZgB1c4WttaR7dYtZMWRSJmNPJlhGJFoxHUESEiMXECwjFzYlHD8nKE82O0YvJ0NWT39eXYyAZG25nHq5dE4An5+XqqubUVuJb2WDTSVVLB08Khs9GRcxMyVQRTJkNCdfNilgNCNTHxEqGhUwKCE9PTliWD1eilpKi1Q/AKapmZOChk5Ad2JFcUcjX1AoYDcdRDojUpJdXqdrVHJLVkIuWEcwWD8nTCgXLTAfQD4tYFM+aH5PQoNQPgCLlY+zr5p0WXpVR3lVLmxEIVAsGEFmPVarb1GLVkyVXVCna1eoa1Z0S1MzGDlHKk5NNlliQFaBVU1/VksAdo2UusSkmpqZVk6DPjNkLBcrSitIeEhRtmlXmlxPk1lKqGhPhlJJglJNPSA8UihNTSZSQh9SeFJTdltWAHubpLvQvejqzGVmeCgvRHZQRX1BNYJIR6JjW3Y/O69qTahlTqViU4pVUjQeN0shSFszUFY5VW9YXmxbZAB8mp+btLexz8pUYGWhzM+hp51zLySSU0arY0CeW0KxZUazb1ZzPjpwMy8yGylHMU9OTXdnbIRjXW5oWGUAhKChlLm8mYyYl1henXhzgrK6WXaGomZQt3BSt3FSp2NIrGVGplY4fmlYQmRpZGd8nby3doqRW1l3WmF3AIalqG+GjF0/P6ZiVY2Cenu4vWCWtYBpdLZqTK5qT5lQR69gRphcR37Bsl2uq0tia7DtyoOmn09Yf1yTpACKnKY0PEFFMyuWmI9yuslzwsZ8jK6Yg6Wnbk2jWj+4ZEeXb1pNc35nratstaptqqFsusaKlIqDXGRff5UAY211HyAhUUxRc2eBZDRfeFZiloN1eH2TbVVek25rmY2DcIaPVWyLYau0fMS1eMnNqJqOxZB2l3RtkWNYACYoKi0pLmkyWGEkVJVhS2JDIEwcPX9ZaZuVfYd3VoNTY2YrVWsmTWl5kFjC0pjHxbqOfZN+dYiOi6trWTBdRFoLFeNgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "effie profile",
            "title": "effie profile",
            "src": "/static/cbb8d5ef4c489fc1691dcb3b19ee8b74/e17e5/effie-profile.png",
            "srcSet": ["/static/cbb8d5ef4c489fc1691dcb3b19ee8b74/222b7/effie-profile.png 163w", "/static/cbb8d5ef4c489fc1691dcb3b19ee8b74/ff46a/effie-profile.png 325w", "/static/cbb8d5ef4c489fc1691dcb3b19ee8b74/e17e5/effie-profile.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1129cebe5fbc1d562d7b74a62c00c953/e17e5/isaiah-profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACsklEQVQoz2OItz2CiRLtjmQ4Hy3wOFriebwqfPvi/q13b1z88/vjvevXdq3eXuixP9f1RKrjEQZMncn2R3LcjpZ6Ha0NOFbne2T9jP03Lx57fO/2hXOnbl05/+LRzcU9RwpdDpT4HEPXnATU6Xq0wudoY9DxrsgT3cFHV3bvf/Tw+tfPbyf09JTkFNw5dfzm6aNTytaUOB9G0ZxgdyTb5Wg5WGd35PFp8aemxO7dtnzH2lUr1qxcHuDpF+Qb1FhdM6eva0XHoZmlqM5OczxS7HmsIeh4D1Bn3ImFqSdWZB3fUnWqp6bLQENPV0nP2sg6MSp28/x5+9Zt3bvqBAOytUAHV/keawk+MjHq2ILUk6tzTm7JO7WnZ+/eZUvMtMwN1Az01Qy9Hdym1LbMz9s4OeIoA3I4AYO3PuB0R+Ky2TlbVmSe3Vp0cn/x6eMNx0+3bowxcpSTUTXXNdFVVFQUEFmZeWR59nkGJDcfLfU+0uR/dnbBjnk105annNhdfOpI59Hza86emr1pr3OQg7qhgbpOiJWGqqhggk3ckjQkm9OdjpT7nKgP3loZV9KQkzmvbMGeeQfOrDhyYMrOvdPWnmru7ygqSQiPKYwPU+LjVpFXcbZORmjOcD5S5X2+InjxtM66lTOmXdu5c1/rkdNlx7avPDhr9uyS+MRvz+4/v3h+aWOXtb6NgWmwtokfTLPdkRS7w2Xh6+a371nWvXFJb1t/Sd3q9B3Hmo5v2rnf290t2N7mza1r55av29I1O9w/V1XXQ980GCnA7A7n+WxqDTtR5bsiLjDCxsFjadH0HU2H1u/eFxcZFeJsf337ns0dMyuS61LDiiysQk0sw1BCO9/teGvwpSSXDgEFE0ePmDvL562o27xuz7627r4wV+f9s5bUpdZXpDbWZjSXJ9dlRpUAAOWrjVqB7pwwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "isaiah profile",
            "title": "isaiah profile",
            "src": "/static/1129cebe5fbc1d562d7b74a62c00c953/e17e5/isaiah-profile.png",
            "srcSet": ["/static/1129cebe5fbc1d562d7b74a62c00c953/222b7/isaiah-profile.png 163w", "/static/1129cebe5fbc1d562d7b74a62c00c953/ff46a/isaiah-profile.png 325w", "/static/1129cebe5fbc1d562d7b74a62c00c953/e17e5/isaiah-profile.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* TODO: This is meant to be a partial, not a page. Don't include frontmatter, and figure out how to exclude it from page generation */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      