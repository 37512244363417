import React from "react"
import { Link } from "gatsby"

import styles from "./nav-bar-item.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const NavBarItem = ({ to, href, children }) =>
  href ? (
    <a
      className={cx("nav-bar-item")}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link
      className={cx("nav-bar-item")}
      activeClassName={cx("nav-bar-item--active")}
      to={to}
    >
      {children}
    </Link>
  )
