import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/characters/sendaria/",
  "title": "Terranova - Sendaria",
  "langKey": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1d7c3ad2f42d2d97c0715cdd47a843fe/e17e5/sendaria-profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAACiaAAAomgEXOiGyAAAC3klEQVQozw3QWVPaCADA8XzVPnRndjp12WItRYiEJCQcCTkgkIscJIEAObgkFqoWkCpWAY/aUVs89mmnH6LO/F9/L3+A5oZGOTC43UK27tX6X/qB/Hm/tPglnd0Kp7e6bTRZcKin593SYuTWB6P29XP3+qH//XHv5gnY+FiDMS8W1YqE16+Ne9Vxf7Dgv90Jy0fRdVpkeFdB5x1hESiLnjSuc+b+1L1+9i/XLx4A441s2tkCDYXrWXJgy4Gv7ldmi+J0qTHQQEG6MvrFpg4ayvnhp6HGjHp2c/XgrNbuxRpgMVuh/A9RHUMcgeo6wp5vuK09NVdraPj7djnZKoDHHfPhavX7eX2+P+oJuD9fWcvHxuInwKFNmR4wtBeHSTZXrQpKyy8VTYbgaCO1KaPhGhE97jg3J7P/flyuJmOPQdoDv7p8Ms/ugW0Eje7kM3RedUiuxEisbeq+bhlFPBxUCJsh2kI6sp2QpMp6dRaHM2ws3K7z6um9+u0OIGkaycFwMYFRqM35Bv+5Lh2q/J6IbQVVOqi+DOBMxZpNx7+WpxVBK0dDelWU5nfK/BaopN1Systl6Uhsm8/5HNNmybZI1ywi4olJNZc4qTevOv2Z2/ra686tBg/+KwwnpZN7fnYDcCmHQjwMlF5tvEEQHQa1GGiZkirD/7QFWM9+6ArC1eTwMugvR5/8As6JZeLrT3LyPX90A4hkuUxwVIpCdqLpRDIPZbE4Xi+g9Me/+jIWqNSx7zoEWYTidRbi46+rAmU6ntPpWs0WUMbhlzgUKsIQA4F4ZKuY3Hb5hBpLVhH0sMZeHAzvzk80GD8y9WY+qWWiJpPRSdSgMEAmMxyeohIJLBJJhjehd5sKkdwVSgFp9HDNSiNTX784mJ42O//Pxz8GPZfHdDZrcZTN00Ds7cYLSITewZvv429D4EZIzcTQv0MOTh8JvovxzQJ0pHuXXvA0Gc0bzY6YEtNQEdmRsqk/v4HtG4QuJ/EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sendaria profile",
            "title": "sendaria profile",
            "src": "/static/1d7c3ad2f42d2d97c0715cdd47a843fe/e17e5/sendaria-profile.png",
            "srcSet": ["/static/1d7c3ad2f42d2d97c0715cdd47a843fe/222b7/sendaria-profile.png 163w", "/static/1d7c3ad2f42d2d97c0715cdd47a843fe/ff46a/sendaria-profile.png 325w", "/static/1d7c3ad2f42d2d97c0715cdd47a843fe/e17e5/sendaria-profile.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f685bec95697482a430c3c8a47ffdb1d/e17e5/hikaru-profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAFxGAABcRgEUlENBAAACz0lEQVQoz2NYJucLQcuVgjbrJEwwiLTWs9PSs9TVs8wwCdzrWLnLrmSbec5G/aRVqmHL5P3g6oGIAUKtUArcpJe01izTTM9Gw8jIyszExNREQd+owynrjF/PHteaHbbFm43SVquFo2teruC/Vjv2gFVRvWWssq6eg42duaWVs7Onqp6Fu4nHmeiZx4P7D3g07rQt2WiQslI5eJmcD0LzatWwzSYZB5xqQs18ZNS0Q8Myu1q6u1u6MnLqLB2D10f2XUtaciy4f79bw3bL/HVaMXDHMyyX91unHbvbpniDS6WpoXV8dsP0matOb9t+9eDBnfsuBqdXNXoXPirYdipm5lH/rt0O5ZsMU1YqBUEsZ1ihGLheP+mYU22XQ6asikpqTt2OvRdeXrv+6MKlIwfPOIckBloH3i/ddTFl8YmQiftda7eaZa1SC4PaDDQGGBK7HSq89J2EJCWFRUSaGtvuXb589fSZOVOnS8qrquuabkyeeSdnw4nwyQc9GrdZ5K7RiIA5Wylwr2lOg2GYtKqapJQsAwNDZGDI89v3bp+/tHHZSiVldQkl1VAr/+sZq09FTAUG23ZkzcsUA3YaZfSZxKqoaimr67CycQiLihzfs/vllSs/Hj8+tHOvlJJyhIX/9fSVJ0In7nOt22qWDY8wBmDQbdFNnG+epq1pqKimKa+kJqui5uHmkZ2cvHb+nBc3rs2fO9fb2PV0zKxjAT1A3wH9uFIpGKp5iazPVvXoEj0/RQMzNQ09ZVVNJVVNSVkFI1NLVU3dsKCg789fxqSkJhoGnPHt3GaVv047brm8P1TzCjnf2Qre5vo26oaW6tqGympaKmpasgoqxfl51nYuIpLy+7Zs2bNlu4yyZo9l4g6jjBUqIYhEslLOb7asp4G2mYaRlaqGrqKKhryymqSMrLCwiJiEpJisYnNt7Zfnr4zMrQzVjFZqRq2Q918KS54AyCIvJAxlWMQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "hikaru profile",
            "title": "hikaru profile",
            "src": "/static/f685bec95697482a430c3c8a47ffdb1d/e17e5/hikaru-profile.png",
            "srcSet": ["/static/f685bec95697482a430c3c8a47ffdb1d/222b7/hikaru-profile.png 163w", "/static/f685bec95697482a430c3c8a47ffdb1d/ff46a/hikaru-profile.png 325w", "/static/f685bec95697482a430c3c8a47ffdb1d/e17e5/hikaru-profile.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* TODO: This is meant to be a partial, not a page. Don't include frontmatter, and figure out how to exclude it from page generation */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      