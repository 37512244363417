import * as React from "react"
import { Link } from "gatsby"
import { WMAnimationContainer, WMWindow } from "@team-chatt/window-manager"
import { FaGlobe } from "react-icons/fa"
import { Body } from "./body"
import { Center } from "./center"
import { StartMenu, StartMenuItem } from "./start-menu"
import { NavBar, NavBarItem, NavBarSpacer } from "./nav-bar"
import { LanguageSelect } from "./language-select"

import "@team-chatt/window-manager/dist/main.css"

import styles from "./layout.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

const noop = () => {}

const startMenuHeader = <>サイトマップ</>

const Footer = ({ langs }) => (
  <footer className={cx("footer")}>
    <div>
      <h3>サイトマップ</h3>
      <ul>
        <li>
          <Link to={`/jp/`}>ホーム</Link>
        </li>
        <li>
          <Link to={`/jp/characters/`}>キャラクター</Link>
        </li>
        <li>
          <Link to={`/jp/press/`}>プレスキット</Link>
        </li>
      </ul>
    </div>

    <div>
      <h3>言語</h3>
      <ul>
        {langs.map(({ langKey, name, to }) => (
          <li key={langKey}>
            <a href={to}>{name}</a>
          </li>
        ))}
      </ul>
    </div>

    <div className={cx("footer_copyright")}>
      <Center>
        <small>&copy; 2020-2023, Studio Terranova</small>
      </Center>
    </div>
  </footer>
)

export const Layout = ({ children, langs }) => (
  <div className={cx("desktop")}>
    <div className={cx("container")}>
      <WMAnimationContainer>
        <WMWindow
          title="Terranova"
          position={{ top: 0, left: 0 }}
          dimensions={{ width: "100%", height: "100%" }}
          isFocused={true}
          onMinimize={noop}
          onClose={noop}
          onFocus={noop}
          onBlur={noop}
          onMove={noop}
        >
          <main className={cx("main")}>
            <div className={cx("menu", "menu--desktop")}>
              <NavBar>
                <NavBarItem to={`/jp/`}>ホーム</NavBarItem>
                <NavBarItem to={`/jp/characters/`}>キャラクター</NavBarItem>
                <NavBarItem to={`/jp/press/`}>プレスキット</NavBarItem>
                <NavBarSpacer></NavBarSpacer>
                <NavBarItem href={`https://discord.gg/h3VnNRQfGW`}>
                  ✨ Discord ✨
                </NavBarItem>
                <LanguageSelect langs={langs}></LanguageSelect>
              </NavBar>
            </div>
            <div className={cx("menu", "menu--mobile")}>
              <StartMenu header={startMenuHeader}>
                <StartMenuItem to={`/jp/`}>ホーム</StartMenuItem>
                <StartMenuItem to={`/jp/characters/`}>
                  キャラクター
                </StartMenuItem>
                <StartMenuItem to={`/jp/press/`}>プレスキット</StartMenuItem>
                <StartMenuItem href={`https://discord.gg/h3VnNRQfGW`}>
                  Discord
                </StartMenuItem>
                <hr className={cx("menu_divider")} />
                <div className={cx("menu_heading")}>
                  <FaGlobe /> 言語
                </div>
                <div className={cx("menu_section")}>
                  {langs.map(({ langKey, name, isSelected, to }) => (
                    <StartMenuItem
                      key={langKey}
                      href={to}
                      isSelected={isSelected}
                    >
                      {name}
                    </StartMenuItem>
                  ))}
                </div>
              </StartMenu>
            </div>

            <Body>
              {children}

              <Footer langs={langs}></Footer>
            </Body>
          </main>
        </WMWindow>
      </WMAnimationContainer>
    </div>
  </div>
)
