import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/characters/cherrygoround/",
  "title": "Terranova - Cherry",
  "langKey": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7959b3dc7359064f48736079d70fc0a2/e17e5/cherry-profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAC4jAAAuIwF4pT92AAADGklEQVQozwXBbVPaBgAA4Pye3e62v7Dty9azs7Z2rvaum9gi00kQAhEI4SUKNMiAgEiRtxgiiCEoCCoUU0AIKkIKwkpPWyt1281r73rbvu95gPB5lXxT9XfL/kbeUWC0cYpg/YhrXmpRSlxuOLNjPCgZclVDvo4WjlUMH1cvWpybWrZvjDcAanDiaVec+Y1dt2qNxr0swXOB8rb7KSrRYHP6TRItcOa9mmGPRzJH8+yR28cYV/d1TM+YEAD6qkF0D6OMO6WbREzSmclRBPwpG3MxQRPp0UWCVlcxD29z8xkOZnnt1qky2VZvdDSxjtlXAEKXvPdVyVPbVtm00kfDttkHkp/vGaDJ30xgwAZlfEiApSWJnIrl1GRNTwkoLSCxjn7tpKKAgdBFzSUU8C2/+JfxJ2O3cM20Ry8PwU9CJlneq7VpprMedN1jnVlOQ3RFQ9fhcAsKN43RZh61AuHeoSNHKXGFb0Wvlz8mrbKCHRII1UXUnLBAuHyC9yp7K3LWZpbhB8rAqTlYTS8QhmjDku4AzlLetLmmxqCIA2EoB22DhehCm8YF0mZHpMuYvLiqLxFKfkmBB/YxqonRraVQzrB7rNupA/ZaPlJMbsNg5tcpp0piRmRBjTRp19pREAYnyafKyrpz361pPE8HUk3Nsyrk59VMy5x7aYxXgWK7Vgg4y/aFNDgTHr6DPry36bFGtHK7enp3Fdu1SdlVa1fgzt9fNnuvYjsv/Mn6ElWRYWE/uQK8oIJ7uDG9iEYVYFAk8o6NEo/uh0e+z+rEvR3foF3pnDVvPl1zp62Nw9cf//2zWDmBzInlCHNcYoCMEYrrlZRCuj43i48/QIaGvN98rfvs831Q8uGfm+fd9+5s+/fLt++u+4O/rnpHRRFotWCOTx/evLsZAPwPI2vjdz2zE16xSH1rSDsynJqfgr74MjUy2ukKYKQkJvKFVv/jf39fXfeThqmYZe4sYepz2fPMFiBM/Fi+fyc0dpvVSRYnHhKzol5qafG7r+hvb7+9OCMP2li8Pvhj0Iw+q6vlnFXRjqjOWfxELimLH/8PdJXYk/3KmNQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "cherry profile",
            "title": "cherry profile",
            "src": "/static/7959b3dc7359064f48736079d70fc0a2/e17e5/cherry-profile.png",
            "srcSet": ["/static/7959b3dc7359064f48736079d70fc0a2/222b7/cherry-profile.png 163w", "/static/7959b3dc7359064f48736079d70fc0a2/ff46a/cherry-profile.png 325w", "/static/7959b3dc7359064f48736079d70fc0a2/e17e5/cherry-profile.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/240bd394ca82d0c62309d240989cd635/e17e5/dominique-profile.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAALiMAAC4jAHM9rsvAAAChklEQVQoz3WS7UtTURyA+0tKxab3nnN23/dyN+/mfKn5gglLW1MrrXxbTk1dNWU6yU2jWUaYqKTZi4QgiCQlpJWou267XptTMxDqU/Wp/6A7CmdE8Pty4Dw8D+d3joVw/HBEANbSTr61W6WRG/G5OzMBZ9RXK9JIhCAE8KM3f8+x5AGAVVXGSlXxfnT828HLkWCzv7Pm5495yW2PcFhET4hq8B8YAjEt/X1F4aetyd3V4UHPxcCVMr/znLw2uv24XSog5EI2aiI3GHQ0IQmH0tLfNdqmp7y3nY55//WV++7nXQ19TZVL/XXxy/pth+7jGU6yUAn+3+wNCoYLNLOBuiHvtYWBjklv8+t7nunuponOms8ey06DMVall0s4xX/YnzRHDIRcRG9VG5Y7ip75Wpcf9kTGeiOjfRO9LYvt5u8+y4FLiDt0kpUJa9ERGMNFEko5VKxcu1dv/HIra3ewfLavbeVB90LQ4yorzqMzhhyaN7W8fEm/Z+OiAikikDRvsFA+zWxXaPdbTDtei99OlRjpoNtVbSsBqSkcwFiIZ1GwzEBMWelYLhUigKL8A4c5JBcwsUr9V7fw6AJfmmM+paXMPG8x8FqQIdAom0FmGnEEzGfQBwsZoeA6ljSjTSuzV6kRXfkvOhuXBj1BZxWVnsojzMQggYY8AQwEECiYy6oXTUQ0CWOJp5by6Hg5t9DmmO2/+SrgHm6t4TCVkYQKqUc4i2Uq2TwFrTRYNxFhNUiaQwhEs8hYKTvTdP6Jzy2NBe422PGUEwqgNDOZKg3ATDTSkdDGAFGnVj7yX3sOc3CzkJ3rqn/a0yGPBwaunsVTjgsJGDKYSq8G2YxaQ8E6CovSiWYF+QVrFzgXKyScagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "dominique profile",
            "title": "dominique profile",
            "src": "/static/240bd394ca82d0c62309d240989cd635/e17e5/dominique-profile.png",
            "srcSet": ["/static/240bd394ca82d0c62309d240989cd635/222b7/dominique-profile.png 163w", "/static/240bd394ca82d0c62309d240989cd635/ff46a/dominique-profile.png 325w", "/static/240bd394ca82d0c62309d240989cd635/e17e5/dominique-profile.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* TODO: This is meant to be a partial, not a page. Don't include frontmatter, and figure out how to exclude it from page generation */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      