import React, { forwardRef, useRef, useImperativeHandle } from "react"

import styles from "./offscreen-menu.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const OffscreenMenu = forwardRef(
  ({ isOpen, onOpen, onClose, label, children }, ref) => {
    const buttonRef = useRef()

    const onBlur = (e) => {
      if (!isOpen) return
      const currentDocument = e.view.document
      const currentTarget = e.currentTarget

      window.setTimeout(() => {
        const activeElement = currentDocument.activeElement
        if (!currentTarget.contains(activeElement)) {
          onClose()
        }
      }, 100)
    }
    const onKeyDown = (e) => {
      if (!isOpen) return
      if (e.key === "Escape") {
        onClose()
      }
    }

    useImperativeHandle(ref, () => ({
      focus: () => buttonRef.current.focus(),
    }))

    const className = cx("offscreen-menu", {
      "offscreen-menu--open": isOpen,
    })

    return (
      <div
        role="menu"
        className={className}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        tabIndex="0"
      >
        <button
          ref={buttonRef}
          className={cx("offscreen-menu_button")}
          onClick={isOpen ? onClose : onOpen}
        >
          {label}
        </button>
        <div className={cx("offscreen-menu_backdrop")} />
        <div className={cx("offscreen-menu_panel")}>
          <div tabIndex="-1" className={cx("offscreen-menu_contents")}>
            {children}
          </div>
        </div>
      </div>
    )
  }
)
