import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/press/screenshots/",
  "title": "Terranova - Screenshots",
  "langKey": "en"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9f8828a503774c2a593f2bf2d3a755e4/29114/avatar-select.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHklEQVQoz53RzUrDQBAH8H0Qk2x2Q48pFUF78SaiCEITK30Uz2YTsh+x2ofxpiBeRSxeemqC7qapL+EmqRCipCD8CDOZ+e8GArAofnMaqnZVa60BRGWLTaUVSxiXT02/MakyqIK8aG0CxFSTzZTD1WCm3Knq36rdO+WIfI9nB2Lpknc9ai7/GZaDm9QVaT/JdNhOitNk4SXz4dWDIzrDdb752VCsh2R+dP18yJ4Qk1vCGv7hMGmJr+PgcUzuz4I3RPPt4QaJRLEfvpwkr+ezBWafrbDshvXlTP+ndW+aYf6xObECbJYj3onluA7Qla43qhHo0RRHS/QvAE9icxwZfuWitOOF8JKiCdeFbqEfolGARsTwItPTNUEesfxy9A35OE7fO4eKkwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "avatar select",
            "title": "avatar select",
            "src": "/static/9f8828a503774c2a593f2bf2d3a755e4/a6d36/avatar-select.png",
            "srcSet": ["/static/9f8828a503774c2a593f2bf2d3a755e4/222b7/avatar-select.png 163w", "/static/9f8828a503774c2a593f2bf2d3a755e4/ff46a/avatar-select.png 325w", "/static/9f8828a503774c2a593f2bf2d3a755e4/a6d36/avatar-select.png 650w", "/static/9f8828a503774c2a593f2bf2d3a755e4/e548f/avatar-select.png 975w", "/static/9f8828a503774c2a593f2bf2d3a755e4/3c492/avatar-select.png 1300w", "/static/9f8828a503774c2a593f2bf2d3a755e4/29114/avatar-select.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/87cf2979a41541ed2d55b61f95d8c7f7/29114/blog-and-solitaire.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blog and solitaire",
            "title": "blog and solitaire",
            "src": "/static/87cf2979a41541ed2d55b61f95d8c7f7/a6d36/blog-and-solitaire.png",
            "srcSet": ["/static/87cf2979a41541ed2d55b61f95d8c7f7/222b7/blog-and-solitaire.png 163w", "/static/87cf2979a41541ed2d55b61f95d8c7f7/ff46a/blog-and-solitaire.png 325w", "/static/87cf2979a41541ed2d55b61f95d8c7f7/a6d36/blog-and-solitaire.png 650w", "/static/87cf2979a41541ed2d55b61f95d8c7f7/e548f/blog-and-solitaire.png 975w", "/static/87cf2979a41541ed2d55b61f95d8c7f7/3c492/blog-and-solitaire.png 1300w", "/static/87cf2979a41541ed2d55b61f95d8c7f7/29114/blog-and-solitaire.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/48febc8b4e1c855abe3f83ef1b34da03/29114/chatting-and-group-roleplay.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjklEQVQozy3OW0hTcRwH8D30IlTQY0T0Fr1EmJesUCQyCSOCejF6LCJBoeilOY2MCCoagYpMw6bN0nB5282xafNYbm3tvp12Oe6qc9vZOTuX/XfOLv/ONPjx5ffw+/x+PxG+qaSshnmtc3rV/3kV/aip1bgaVegD7vev5hVKrS2p9QG1k9R5KLWLXP8L4g530brGOxARdCl14sdv7vR8nVI6UrwzTvlSrDtBBUi4Y1zZ+DDh3/REg3HMu435IyFPOJUgye0wsBhKAi7blPKbnRP116PjCkCRZQKHBaoKGAgr2Rjm0/+MeeLhbTwY2MOwbCxCpDPlXCgIfhtrmAu60PERyal6/dvhPF1giHwJFEsAQAh3/N6Yz07sxNOBMB5P5vcy+VSaJRkSCwDLPiaRSdm1toXTHRapLJtnaJyA5VKlWBTw2ty8dctCEEwETWYSOQZn6CwNaL6GDy5Lmpuu1h1Tn+lyvB4RDrN4DtA0x7ICXpTJbSakQJBJdyiXzACSAQRdZEqZQKB4gC+LDt04elzXeMs/KhdAmWUrHFfefxszqJf0P9DEHpXeJbNpmsALZI4rsNkg+h/3njjZffiYWDql9UYgrAKO5/kSX+R4CHFE/Wh0bhFN15ZWqkLCai3JWJg1Gzg7IvKIH3TXHbk3NLbkiu4PVWojlbLQx/XLky/6VxQzdp0Gs/7Cwz4mEWZ2o3mftWozQqdJ1Nd5rqep/fs7qeaTwvRl1qBbt28g5uWlrfUN89SIRfpypufh3bOtsiGp3WByGBC7EfEi5pD5T9BsE7UP3257Ptk1uNzSKz9/f6yhb/rKwEKHRNn8dLbhybdGifbSgKpVorrYr2kZ1DUL2a++IFY1PlM1iFX/APbw911TjihzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chatting and group roleplay",
            "title": "chatting and group roleplay",
            "src": "/static/48febc8b4e1c855abe3f83ef1b34da03/a6d36/chatting-and-group-roleplay.png",
            "srcSet": ["/static/48febc8b4e1c855abe3f83ef1b34da03/222b7/chatting-and-group-roleplay.png 163w", "/static/48febc8b4e1c855abe3f83ef1b34da03/ff46a/chatting-and-group-roleplay.png 325w", "/static/48febc8b4e1c855abe3f83ef1b34da03/a6d36/chatting-and-group-roleplay.png 650w", "/static/48febc8b4e1c855abe3f83ef1b34da03/e548f/chatting-and-group-roleplay.png 975w", "/static/48febc8b4e1c855abe3f83ef1b34da03/3c492/chatting-and-group-roleplay.png 1300w", "/static/48febc8b4e1c855abe3f83ef1b34da03/29114/chatting-and-group-roleplay.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4c1ebcd44c4381205ce4e4e8d564c9d6/29114/chatting.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AKihi6uRjoFga3JtX6SlgLq8ndfiz9q2koxeVHFGQquln6rExouKv6u73sXd+8Xc+8fe/Mbe/M3e/t3BkgCjkHxTMGEqE0NGMEs1J00sJ0p0WmWTVT2UYFSzgG+OXEusmox0WH/Dt8jz8Pn38PX89vj//P3+/v/l16gApqWOVkZnXjJEoGRVj1hQRSFCXTdRa1FSonxx0aSNp3tuipyYRz1bs6y+6u/78PD49fX68vP4+Pn/vr+zAKKxsYuMjIZoYqpeRZhbQ1lPXGVqf2toc8WYhu2wkomjpV3Cy1A7XLSrv+fm9enm8+7s9fHv+fX0/9LSsgBcXGF5dXt6ipSoaVV6dnNxvLSJjot0kJuspp6GnaGIxcOaurJANVi2sMDr7/ry8fn29fz6+f76+P/F3cIATDg6fExWeD9nbUZUa0NhbJmtopCDc3WIsaSuztnetMTQf4ysRC9Xua3C5+T26eb17u347Ov18e78r83CAEIvJJldi8xTsnZYaV45Ln9VWmhSay5lllNznmFromtrmVJgkX2fuc7X4PTy9vHx9vPy+PTz9/f0+7XWvgCxmbyefadYNlNjS2guITpWMSMoNlQbabEqWoxBZqtPcLhQfMBgosHK3Of////8+/3//f3+//7///+44cQAvafLOTJRMChGZzxjQiNTGwkoHR1MQ1qNU1iUNFW4Qm2/TnSyeXubwcnJutTEuMjGu8/Pu9HQu8rOjrO1AD9WVCQ3YzE/cj9DbFtKiEJCfCMqZGeCpEVUrUt2sWW8r2S0mWWLsnqoxUGZlEGeqVivyVaqwVCXumeswABBhVItYoUsW4sqXIcpYJAtZZgpZJMybZ0xaZ0vbpkqbJUyapM6b5czZpUsaZcuaZc2apk2aZkxapouaZpCwHntw4Nj8QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chatting",
            "title": "chatting",
            "src": "/static/4c1ebcd44c4381205ce4e4e8d564c9d6/a6d36/chatting.png",
            "srcSet": ["/static/4c1ebcd44c4381205ce4e4e8d564c9d6/222b7/chatting.png 163w", "/static/4c1ebcd44c4381205ce4e4e8d564c9d6/ff46a/chatting.png 325w", "/static/4c1ebcd44c4381205ce4e4e8d564c9d6/a6d36/chatting.png 650w", "/static/4c1ebcd44c4381205ce4e4e8d564c9d6/e548f/chatting.png 975w", "/static/4c1ebcd44c4381205ce4e4e8d564c9d6/3c492/chatting.png 1300w", "/static/4c1ebcd44c4381205ce4e4e8d564c9d6/29114/chatting.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4525c2dd3a55dfd57e1ced5a0663dc2c/29114/end-2003.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeklEQVQozyWSyW7aABCG/RqVqiwqwWYHm6XssQNeIGBjMIZgO3gJJjQ7lLZZFAjNBoQ4gSTtA/TSV2hvPfUpeuu1fYS6rfRpNBrpk+bXDFCX6zzH0zQnKnr73cnro177sNc5PtO39yVlQ5C1iihXJaVW10SlYSKpzUZrr9Npv+1uAZIk+jzBcCBGp4jeaf/8/PLqejSbPUqa7o2gLzEqhJL+RBqOot5w0h2MmXhCCU1vnJy0gQ29EU9gkVAMjyZ7/cHl9ehmYkxnD/r2AVXgyDxLMcWVDL2cZRMkE8NzYYzyJ1LrWsP0gY3NrUqtVmTLERfc7w+Gw/FwPJlOH+TmDqIaofZnV+sTKBrhVDaEEsFl3J9MIfEVbbPV7u4DBZbJ00ylIqYCkbP3F6OJcTW6Me6nZshnu1+s09/g9Jfl+DvoDVhd3hcOt1ltXr/U0A+O3gAwjCzHkxmMJP2R0/7g4npsLm7cTUW1mex/jT7+DIx/uI6/wXEsEE+6gmEsnaFpXm696vaPAJvNTqazYkHIYsTg/PLm9s7k8ekjL8hxigvkNCcqLMU5i8MDun1WtxfywJAbrspK+/QQUJXmGr+OYoS60xmOb4372X+5KsmLNqcLQRw+2O7x2n1+mw8xZYvDPbcE1VT1r7y323W6kUgMLVbFq6EZ+X48uX14+lAWpOcWq8XpWYQc86B9zgotgLYF0D4PQouQnRMkSW8C0QQKOZFQBMPJXDbL5vKlWBJnStVCWaCY0mqJJxmWpFkiX8BzTHqVwXMF83jmS7GCAqxt1lfXZIpT8KJEFCWyVMeYGlaQ0sU6wckkr5K8kqloFK8SZcXk30TFOdns/wA6oeMVnFiE9QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "end 2003",
            "title": "end 2003",
            "src": "/static/4525c2dd3a55dfd57e1ced5a0663dc2c/a6d36/end-2003.png",
            "srcSet": ["/static/4525c2dd3a55dfd57e1ced5a0663dc2c/222b7/end-2003.png 163w", "/static/4525c2dd3a55dfd57e1ced5a0663dc2c/ff46a/end-2003.png 325w", "/static/4525c2dd3a55dfd57e1ced5a0663dc2c/a6d36/end-2003.png 650w", "/static/4525c2dd3a55dfd57e1ced5a0663dc2c/e548f/end-2003.png 975w", "/static/4525c2dd3a55dfd57e1ced5a0663dc2c/3c492/end-2003.png 1300w", "/static/4525c2dd3a55dfd57e1ced5a0663dc2c/29114/end-2003.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c0a61be95053d8af8631be14bde5c183/29114/friends-blogs.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9ANfWy93h39jg593h48jNyai0s667vKiyuq64vrXBxqi1vOLl7MjZ5qS0tcu6sq+bjKChm6GbroeGn42GpwDm3MTX3tje4u317eXJupp6d2NbW017eXWWkYt3eXI8Ozbq4uDN4e+OpKm8qKa2n5agkI+OjqGIh5KMgKEA4NzI0NvY2+Hr8+7o07ykvK2fraKYp6WWVlNKJyIiOjY36ufmysPDmpSOvqOdv6Wgn4aDiHuAlYWMdmx6AN3l6Mvd4dzi6t7c2r6hi8SQbr+Ueri1qaytpm16b0BVSfDx7r2urZ+Jftm5t8akrLidm4Nxb4V2dFlcdADo3sLj5Nzo6PHt5+Ktn4ymlYKwoI2dmpOfnJeKkIJ2hHL4+fepoKeVhoO3maOylKKpmZVuXmFuYGZQVW4A8Ozm8O7r7ejt+vfx0MSts6ufw8C2tLCkq6ecp6Sdy8a4////oJqfkoqOfX+Qk42ZV1FeXU1SS0JKTFCAAPDv8e7u7vDq8OTi3biwm399dXd9d5GTjYaGf5WbjLW1p////8G4vaugpNHExaaaoVJOXDgwNDYtN0hJbADv7+7s7Oru6u/a3NhWXVBDR0NicWhGTEoxLi02ODJaXFPt7uzVy9CzpaTKu7ttZGlBP0ZVTVaomaSdl6AA8+3w29jK49vV39rZPzYuU0Q9sIVsfF9FVUxHPjk2WFtY8/Hwxr7App2XrqaijYiHPEJDXmBjubO6uJeiANTp1cDRzLzKzbvL1J2utJaptZyjqZucnZquuI+jsZeturTI2Iygr4udq4aZp4aZqkxpeERdcHlkg4lmgwBGilQ+dI5CcZs2apQsZ5csZpUpZJUqZpgtZpYrZZUrZJMpXY4xYpE1Z5YvaJcwZpUyY5IyZJMvZJQrZJVJvJHi9D9isgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "friends blogs",
            "title": "friends blogs",
            "src": "/static/c0a61be95053d8af8631be14bde5c183/a6d36/friends-blogs.png",
            "srcSet": ["/static/c0a61be95053d8af8631be14bde5c183/222b7/friends-blogs.png 163w", "/static/c0a61be95053d8af8631be14bde5c183/ff46a/friends-blogs.png 325w", "/static/c0a61be95053d8af8631be14bde5c183/a6d36/friends-blogs.png 650w", "/static/c0a61be95053d8af8631be14bde5c183/e548f/friends-blogs.png 975w", "/static/c0a61be95053d8af8631be14bde5c183/3c492/friends-blogs.png 1300w", "/static/c0a61be95053d8af8631be14bde5c183/29114/friends-blogs.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ccadf852740ee9805ffd62b6281fe168/29114/rss.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACpUlEQVQozz2OWVNSAQCF729pyqbhwWyc6amHHnqqx0Yt29VxQZgQUTTKRlGhBFNmJCRkNRfcUOReBZeUVRYNKRVFIBaV2C4XuKwS0zSd+ea8fXMO4NcMHndXMDjcLsFc28h0G3uGwl2gfJH9ayE0I/9m7G35ScOFnODuHPNI2pt0yXInE7CRD6A7PUHmQ9WqbNVgWlJtLa5tLa1vyzfUy3+BNEajxaYltYDVVWdagXqsU/Xivq2vzq/j5c/XgYiV7ZIQw2c+JIE63S6P1+t0uuIJNH9ZyObyuctCOonOE5uVH8i7ol4Tr0tLxdqaHunxtWGbDDDr1nYhhSdw5vMGzHpr4HcUSaKxeLIIHE9EkEQ4GN6k9Rs2leoJnoZFBgeISzVVe88q7BoFYJyibpMag4nEjER2+8rNH3t2OBaNRGEUTaVTxdU0Eke29w5GdW4Vnc64e4f25DG78RXYXGvftwB2h9Fi3PCf+y2mXwyq1O0MhmNIJBxBkHgmnUHRdBSJfz/cN23IwE+UoepKVlXlYjtxUyrUOo4A9ZF5zX94en6RjSKFSCyTyadzhf9JFGUY0U+PiGmE0R6iYqSb++71VFcre2WetbMCUKVzHEjpiCLuFVDTTQp4PCE4lkBTxcOpdLYoX4RgJYcm6sEpBt8oB8kfKdhO5tthg+KzTg20v58mD3wNIHDoxOaFZvPRSDqXL1xmC4XigXw+l4VjSP8CJOb1i+h4Oqmm9fkDBq9P4tLM+46BDs5kE3edt2yGdAcqw4lM65Tp3QuGU7nJKbd4Ic2BVO/oAK2tsyB1eHB0UkDnc1jSSabNKnT5gHtDlaU4ZsnT4WsvWdcb+CX1gqt1YzewIkyzuKRBgKnnYZr45ThhGV6MwQrK8IJyguQWYbyMICkljP8B4jPWG5s5fvUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rss",
            "title": "rss",
            "src": "/static/ccadf852740ee9805ffd62b6281fe168/a6d36/rss.png",
            "srcSet": ["/static/ccadf852740ee9805ffd62b6281fe168/222b7/rss.png 163w", "/static/ccadf852740ee9805ffd62b6281fe168/ff46a/rss.png 325w", "/static/ccadf852740ee9805ffd62b6281fe168/a6d36/rss.png 650w", "/static/ccadf852740ee9805ffd62b6281fe168/e548f/rss.png 975w", "/static/ccadf852740ee9805ffd62b6281fe168/3c492/rss.png 1300w", "/static/ccadf852740ee9805ffd62b6281fe168/29114/rss.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/00dc023a63bbc915a0a8287adb94f54b/29114/solitaire.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AOfFjuGFU+eEXeCDZs6QcYGar3602HOqzHuozXugx3icwnmjx3SfxHOcw4Wy0r6ajNWjffTbrvzdqPXSowD2yoPwjlLjgFjZe2G1jnxQkJZWnKOv08U7o4Vaspim1Meby7ybzb+Wx7im1cqEj3vCY0vRfmDivpb86L4A7dGb4qt57J5mwnlhan6AGWqLN4Sm3OjdBY5lRqyM0Obgwt/SudXLnr602u/pl6GIzIVq021cyWhf68urAMvDu9S9ude0iKWXjqWgi0KPgUOhjV2wlTCnhEGtjFmzlkWqk06sklWwlECklIGafsKLcq1mWs1aU9q8rgDMqoXLe2y6wrlshLKJiYexv6/F1tHU5+DE2tHR49zLzcSqxsWqyMOoxLuv0tOBgnWHTUyVbGWyamHczsgA2dzN19LCwMG/cprGSnuimaqpts7Ea7ujpMy9mc+9abiev8zEjcOyYrCZ5PLtgYh9kE5OsnZyvaWbyt/YAMHVy8Pt3KO7vHuqwVqQnJ21s6DLuwCHWxaZcw6YbwOWaqjKwUiqjQWQZY3HuHqUf6OAcaZ9cpa0qpPNvQCDqa6bvcC23tCAl6ZWf4mly8GQwbIAhVwFj2UFkGQHj2Ucl3EPkGgFiWEAgVlbhGqcfWx1iniQxrOaysQAeH+qeo+p1ezVtcW+i5mZd5iQX5CNRYyEVn1zTYqMRJSYQZGOQpaMV492XYZob3ljeF5LjJZ/ot7CsOTYAHmaqGydwH6ev5a3ybHQ13ORvWeSwXCaunmfxHqp3oCgy3ey2Hq62HywvoeQkJ2ooYihnJOopozS0aDf5ABDiVMvZoImXJEqY5QpY5YuaJsrZZguapsxbp4rZ5cxZJIwZ5UzZpQwZpUoZJQuZpQ1aZgyaZkxapgvapk86YRFa3dnxgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "solitaire",
            "title": "solitaire",
            "src": "/static/00dc023a63bbc915a0a8287adb94f54b/a6d36/solitaire.png",
            "srcSet": ["/static/00dc023a63bbc915a0a8287adb94f54b/222b7/solitaire.png 163w", "/static/00dc023a63bbc915a0a8287adb94f54b/ff46a/solitaire.png 325w", "/static/00dc023a63bbc915a0a8287adb94f54b/a6d36/solitaire.png 650w", "/static/00dc023a63bbc915a0a8287adb94f54b/e548f/solitaire.png 975w", "/static/00dc023a63bbc915a0a8287adb94f54b/3c492/solitaire.png 1300w", "/static/00dc023a63bbc915a0a8287adb94f54b/29114/solitaire.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2ecfafb1bd007e70f7a63d826c7e0067/29114/taskbar-photos.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClUlEQVQozzWRXUhTcRjGjyHsqovovrugG8nImUUKgmBElEZEF0kEaRdDkxKlVUQKfuDHhpkUKAmlkuncKkmw2c6+3HQ7Z2dnX859z+nZ5uY+ztnO2dnOv2Piy+/u4Xne9+GFCJ08b4HdX6d/dT93z00BFAaoCiAqYFWTg22OO9W65tri+xeMGaZRHY1qeQqo5hgIWGXyrvarkKBOcOZVzwfZuvOnIfRD6/tjIfaHe4j6c9Tt86VnN4BDByx/j8Bg1qI9MdtkU3WXxacg08PGPQ/lc7LRWPGA4Yg8iDnthZEOY6doqaNnQ41s6nGD3oZpTFmjirX8N68o8cHB+b6mp2tj03PGpAxJr9izoUMGABC3Y986RGoXYSOBNc7pQvRve8IWZQ8xM4vAR+ahle0ZK/PRyj34HhEpiF6YfK3MrCLh5G7485ve61B5xOPhg5gcmYpFrKYNt8uedeOsWVVAtVA+4QEcy8tapUWP79rjhSAHZkcnG6DyxrLTDYKzQbeX5biDRJKIxiL7hEZv8GmUANMy/GYyStBsqQiAYkG9uhXU+NI4BSbbxXfLBIs3L4krLrjw7QJbSKfTFEXRdJ7K03Fsq3h8NpM5LJZKJQAMaFjtSuiDWb6h+HHXTEsLeNeUfXk/vRvho7NZMpenQ6FwIpVOOTHGpKIRLQROZk3tWrfHTREGyQDTp4Gg6NpeZ/Ni7cWDQIBXuVKRJ0dmSIrMbWMA4d+mgbrl8KNFok2eaP3i71wIP1lO3luiZvsHUv23vCMieOitWrasmFfAsNmCe1BsZxNx2TZMfoPRZ9iE6mWtlVLjlTF/tcQjlHiqpd4aqbd2Iigc8wlHd2omwlVDjoo+vHJ4RygNVEn8QklAOB4Sjod5/gF2/+omI4nKPgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "taskbar photos",
            "title": "taskbar photos",
            "src": "/static/2ecfafb1bd007e70f7a63d826c7e0067/a6d36/taskbar-photos.png",
            "srcSet": ["/static/2ecfafb1bd007e70f7a63d826c7e0067/222b7/taskbar-photos.png 163w", "/static/2ecfafb1bd007e70f7a63d826c7e0067/ff46a/taskbar-photos.png 325w", "/static/2ecfafb1bd007e70f7a63d826c7e0067/a6d36/taskbar-photos.png 650w", "/static/2ecfafb1bd007e70f7a63d826c7e0067/e548f/taskbar-photos.png 975w", "/static/2ecfafb1bd007e70f7a63d826c7e0067/3c492/taskbar-photos.png 1300w", "/static/2ecfafb1bd007e70f7a63d826c7e0067/29114/taskbar-photos.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/da473fa73a91c859b8de9d900853e166/29114/terranova-blog-fanart.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AOvFsOjJvo6qroahr3qcrnebr36esIOjtYGktoant5CwxaDC47Kntqu40KW30qa20aW30qy3z+e/vPvQwwD/0a22fHSfbHyweI2waYGzaXmAWmmKXnquZH6WWXl7W3yPbIOforLF2/TD2vDP5vrP5/vU6f3m0NH4zL8A/MyqmEVDkmKAp36SiGiFfmF9VVd7Yl2JglBze0t4ZFR2sExi5Juh//Xm/u3i//Dl//Pm//ru8d/a98q+APvc0syRjjg2UCE7UQw2UAg5Ugs5UQw4Ug02ThMxSS0+VHYvRMaRkv/rx2xkZZyPhP/ZqPnfvunX1/jLvgD0x6KQPjsdLD4UQ04PQEwJKzcHJzQJKTYEJjITLj+Fg52zOlfRkpfn2sOjhXixkXnhvnz/5sTo1tf4y74AgTo3PAALKjlMJENQGDRDDyw7Dyw7ECw7DSk4HTVGdXiMujdXz42X1d7M38e1qIZvUUIy8tq+69jZ98q+ADwABEQGFCU1SB9BThYzQhc1QxY0Qhc1Qxc1QxQzQBM4Q7Q1VdaUnt/r4/jlzaabjFRUV83Atu/c2/bKvQBsGRxTDhUaJzoCIjEVMkEhQE0TMUAFIjIGIzIFIzISKzx0lKa6z9r19Pjr7vT6+/3///74+/7q2tj1yr4A03hjTgcNFic4BiczDSs3KklUDyw4BiMvDSo3ABwqDSUxVIujtdDg//35+Pb1/Pn3/fr5////9N/Z/Mq6AG9IL2Y3RJSJlIaGkXp3g4eToH2IlXuBjYSMlXmAinqImIyQnaCgrMHJ18nR3sfQ3cfQ3cbR3srJ09vIzQA9f0w+bohOcJhEaI86Xoc8ZY06a5dBcp5Abpg7apU7bZs8Z5Q3X400YZAyZpY1ZpQ5ZpM4ZpM1aJgzappgwVlq4T/SggAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terranova blog fanart",
            "title": "terranova blog fanart",
            "src": "/static/da473fa73a91c859b8de9d900853e166/a6d36/terranova-blog-fanart.png",
            "srcSet": ["/static/da473fa73a91c859b8de9d900853e166/222b7/terranova-blog-fanart.png 163w", "/static/da473fa73a91c859b8de9d900853e166/ff46a/terranova-blog-fanart.png 325w", "/static/da473fa73a91c859b8de9d900853e166/a6d36/terranova-blog-fanart.png 650w", "/static/da473fa73a91c859b8de9d900853e166/e548f/terranova-blog-fanart.png 975w", "/static/da473fa73a91c859b8de9d900853e166/3c492/terranova-blog-fanart.png 1300w", "/static/da473fa73a91c859b8de9d900853e166/29114/terranova-blog-fanart.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* TODO: This is meant to be a partial, not a page. Don't include frontmatter, and figure out how to exclude it from page generation */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      