import React from "react"

import styles from "./start-menu-panel.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const StartMenuPanel = ({ header, children }) => (
  <div className={cx("start-menu-panel")}>
    <header className={cx("start-menu-panel_header")}>{header}</header>
    <div className={cx("start-menu-panel_body")}>{children}</div>
  </div>
)
