import * as React from "react"
import GlitterText from "./glitter-text.inline.svg"

import styles from "./body.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const Body = ({ children }) => (
  <div className={cx("wrapper")}>
    <div className={cx("main")}>{children}</div>
    <GlitterText />
  </div>
)
