import SendariaImages from "../../../../src/markdown-pages/characters/sendaria-images.mdx";
import TourmalineImages from "../../../../src/markdown-pages/characters/tourmaline-images.mdx";
import EffieImages from "../../../../src/markdown-pages/characters/effie-images.mdx";
import CherryImages from "../../../../src/markdown-pages/characters/cherry-images.mdx";
import * as React from 'react';
export default {
  SendariaImages,
  TourmalineImages,
  EffieImages,
  CherryImages,
  React
};